'use client';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <h1 className="ml-8 mt-8 text-2xl font-bold text-gray-600">
      Sorry! Es ist ein Fehler aufgetreten
    </h1>
  );
}
